import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { StyledUl, MenuItemWrappper } from '../../DropDown/style';
import { ImageUrl } from '../../../utils/constants';
import {Layout, DropdownHeading, MenuItem, CheckMarkContainer} from './style'
import { withTranslation } from 'react-i18next';

class StyledDropDown extends Component {
  constructor(props) {
    super();
    this.state = {
      open: true,
      title: props.title
    }
  }

  showMenuItems = () => {
    this.setState((prev) => ({
      open: !prev.open
    }));
    this.props.hideDropDown();
  };

  componentDidMount() {
    const stringArray = this.props.history.location.pathname.split('/');
    let dropdownTitle =stringArray[stringArray.length - 1];
    this.setState({
      title: dropdownTitle
    })
    document.addEventListener('click', this.closeDropDown);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeDropDown);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.history.location !== this.props.history.location) {
      const stringArray = this.props.history.location.pathname.split('/');
      let dropdownTitle =stringArray[stringArray.length - 1];
      this.setState({
        title: dropdownTitle
      })
    }
  }

  closeDropDown = (event) => {
    if ((this.wrapperRef && !this.wrapperRef.contains(event.target)) && this.state.isOpen) {
      this.setState({
        isOpen: false
      })
    }
  };

  selectMenuItem = (item) => {
    const { history, onSelect, updateCategoryName} = this.props;
    this.setState({
      title: item.id,
      isOpen: false
    });
    history.push(`/events/ondemand/${item.id}`);
    onSelect(item.id);
    updateCategoryName(item.name);
    this.showMenuItems();
  };

  categoriesCard = [
    {
      name: 'Health',
      background: 'rgba(253, 113, 117, 1)',
      color: 'rgba(159, 201, 137, .15)',
      icon: '/public/images/AdminReports/Health.png',
      image: '/public/images/AdminReports/Health_white.png',
      id: 1
    },
    {
      name: 'Nutrition',
      background: 'rgba(159, 201, 137, 1)',
      color: 'rgba(159, 201, 137, .15)',
      icon: '/public/images/AdminReports/Nutrition.png',
      image: '/public/images/AdminReports/Nutrition_white.png',
      id: 4
    },
    {
      name: 'Workplace Wellness',
      background: 'rgba(246, 180, 121, 1)',
      color: 'rgba(246, 180, 121, .15)',
      icon: '/public/images/AdminReports/workplace.png',
      image: '/public/images/AdminReports/workplace_wellness_white.png',
      id: 6
    },
    {
      name: 'Physical Wellness',
      background: 'rgba(105, 194, 255, 1)',
      color: 'rgba(105, 194, 255, .15)',
      icon: '/public/images/AdminReports/fitness.png',
      image: '/public/images/AdminReports/physical_activity.png',
      id: 3
    },
    {
      name: 'Lifestyle',
      background: 'rgba(157, 135, 169, 1)',
      color: 'rgba(157, 135, 169, .15)',
      icon: '/public/images/AdminReports/lifestyle.png',
      image: '/public/images/AdminReports/lifestyle_white.png',
      id: 2
    },
    {
      name: 'Emotional Wellness',
      background: 'rgba(255, 153, 134, 1)',
      color: 'rgba(255, 153, 134, .15)',
      icon: '/public/images/AdminReports/emotional_wellness.png',
      image: '/public/images/AdminReports/emotional_wellness_white.png',
      id: 5
    },
  ]


  render() {
    const {title, open } = this.state;
    const { isCompanyDashboard,  isOpen, MenuItems } = this.props;
    return (
      isOpen && open && (
        <Layout isOpen={isOpen} isCompanyDashboard={isCompanyDashboard} >
          <div ref={(node) => this.wrapperRef = node}>
            <div>
              {

                <StyledUl>
                  <DropdownHeading>
                    <div className={isCompanyDashboard ? "companyname" : "title"} style={{color:"#005c87"}}>{this.props.t("Categories")}</div>
                    <div>
                      <img src="/public/images/NewDashboardV2/closedropdown.png" alt="back-arrow" onClick={this.showMenuItems} />
                    </div>
                  </DropdownHeading>
                  <MenuItemWrappper padding>
                    {
                      MenuItems.map((item, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => this.selectMenuItem(item)}
                          isActive={item.name}
                          color={item.id==title}
                          border={(index+1)===MenuItems.length}
                          style={{color:"#005c87"}}
                        >
                          <CheckMarkContainer>
                            <img src={`${ImageUrl}/${item.grey_icon}`} />
                          </CheckMarkContainer>
                          {this.props.t(item.name)}
                        </MenuItem>
                      ))
                    }
                  </MenuItemWrappper>
                </StyledUl>
              }
            </div>
          </div>
        </Layout>)
    )
  }
}

StyledDropDown.propTypes = {
  title: PropTypes.string.isRequired,
  MenuItems: PropTypes.array.isRequired,
  isCompanyDashboard: PropTypes.bool,
  isAdventurePage: PropTypes.bool,
  eventKey: PropTypes.number,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  isReports: PropTypes.bool,
  isOpen: PropTypes.bool,
  hideDropDown:PropTypes.func,
  updateCategoryName: PropTypes.func,
  t: PropTypes.func
};

export default withRouter((withTranslation())(StyledDropDown));
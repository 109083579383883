/* eslint-disable no-console,no-undef, camelcase */
/* eslint-disable react/no-unknown-property */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getActiveEvents } from "../../../redux/actions";
import { LayoutNew } from "../../EventDetails/styles";
import { BuddyTabContainer, BuddyTab } from "../../PeopleHomeV2/styles";
import HoverButton from "../../common/HoverButton";
import { CompletedChallengeWrapper } from "../styles";
import { WolibaCard, WolibaButton, NoEvents } from "../CompanyLiveEvents/styles";
import AboutTheEventModal from "../AboutTheEventModal";
import isUndefined from "lodash/isUndefined";
import isNull from "lodash/isNull";
import Waiting from "../../Waiting";
import { ImageUrl } from "../../../utils/constants";
import moment from "moment";
import { convertDateInTimezone, convertMonthFromDate } from "../../../utils/methods";
import LazyImage from "../../common/LazyImage/LazyImage";
import {CoachCard} from '../SingleEventV2/styles';
import { InfoIconV2 } from "../../../utils/icons";

class LiveEventsMain extends Component {
  constructor() {
    super();
    this.state = {
      pageNumber: 1,
      tabValue: 1,
      showAboutEventModal: false,
      eventCalendarId: null,
    };
  }

  componentDidMount() {
    const { getActiveEvents } = this.props;
    const { pageNumber } = this.state;
    getActiveEvents(pageNumber);
  }

  updateTabStatus = () => {
    this.props.history.push("/events/schedule")
  };

  toggleModalV2 = (event) => {
    if(event){
      event.stopPropagation();
    }
    this.setState((prev) => ({
      showAboutEventModal: !prev.showAboutEventModal,
    }));
  };

  toggleModal = (id, event) => {
    event.stopPropagation();
    this.setState((prev) => ({
      showAboutEventModal: !prev.showAboutEventModal,
      eventCalendarId: id,
    }));
  };

  backButton = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <mask id="a" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
        <path fill="#D9D9D9" d="M.645 0h24v24h-24z"/>
      </mask>
      <g mask="url(#a)">
        <path d="M6.395 12c0-.358.15-.717.45-.99l9.428-8.6a1.64 1.64 0 0 1 2.172 0c.6.547.6 1.434 0 1.981L10.102 12l8.343 7.609c.6.547.6 1.434 0 1.98a1.64 1.64 0 0 1-2.172 0l-9.43-8.599a1.34 1.34 0 0 1-.448-.99" fill="#007AB1"/>
      </g>
    </svg>
  );

  liveEventsAPICall = (pageNumber) => {
    this.props.getActiveEvents(pageNumber);
  };

  headerView = () => {
    const { tabValue } = this.state;
    return (
      <BuddyTabContainer marginBottom="25px">
        <div className="backButton">
          <HoverButton
            title={this.props.t("Back")}
            svgPath={this.backButton()}
            onClick={() => this.props.history.push("/events")}
            width={"25"}
            height={"25"}
            activeColor={"#007AB1"}
            fillOpacity={"1"}
            marginLeft={"0px"}
          />
        </div>
        <BuddyTab
          width
          padding
          active={tabValue === 1}
          // style={{color:'#005c87'}}
          margin={"10px 0px 10px 10px"}
        >
          {this.props.t("Live")}
        </BuddyTab>
        <BuddyTab
          width
          padding
          active={tabValue === 2}
          onClick={() => this.updateTabStatus()}
          margin={"10px 0px"}
        >
          {" "}
          {this.props.t("My Schedule")}
        </BuddyTab>
      </BuddyTabContainer>
    );
  };

  onScroll = (e, events) => {
    const { pageNumber } = this.state;
    const { totalCount } = this.props;
    const bottom =
      Math.round(e.target.scrollHeight - parseInt(e.target.scrollTop,10)) ===
        Math.round(e.target.clientHeight + 1) ||
      Math.round(e.target.scrollHeight - parseInt(e.target.scrollTop,10) + 1) ===
        Math.round(e.target.clientHeight + 1);
    if (events.length < totalCount) {
      if (bottom) {
        this.setState(
          {
            pageNumber: pageNumber + 1,
          },
          () => {
            this.liveEventsAPICall(this.state.pageNumber);
          }
        );
      }
    }
  };

  handleWolibaLive = (id, attending, event) => {
    event.stopPropagation();
    this.props.attendEvent(id, attending, 1);
  };

  sortDataByDate = (data) => {
    const sortedData = data.sort((a, b) => (
      new Date(a.start_date) - new Date(b.start_date)
    ));

    return sortedData;
  };

  redirection = (route) => {
    this.props.history.push(route)
  }

  render() {
    const { showAboutEventModal, eventCalendarId, tabValue } = this.state;
    const { activeEvents, role } = this.props;
    if (!activeEvents || isUndefined(activeEvents) || isNull(activeEvents)) {
      return <Waiting />;
    }
    let activeLiveEvents=activeEvents && activeEvents.length?this.sortDataByDate(activeEvents):[];
    return (
      <LayoutNew>
        <div className="containerDiv">
          {this.headerView()}
          <CompletedChallengeWrapper
            // overflow
            // height
            // onScroll={(e) => this.onScroll(e, activeEvents)}
          >
            {tabValue === 1 && activeLiveEvents && activeLiveEvents.length
              ? activeLiveEvents.map((data, index) => (
                <WolibaCard
                  width="calc((100% - 50px) / 3)"
                  margin={(index + 1) % 3 === 0 ? "0 0 25px 0" : "0 25px 25px 0"}
                  key={index}
                >
                  {/* <div className="eventLabel">
                    <div className="label">{data.is_global_event=="1"?this.props.t("Woliba Live"):this.props.t("Company Live")}</div>
                  </div> */}
                  {data?.coach_details?<CoachCard onClick={() =>this.props.history.push(`/events/${data.id}`)}>
                    <div className='cardWrapper'>
                      <div className='imagecard'>
                        <img src={`${ImageUrl}/${data?.coach_details?.image}`} />
                      </div>
                      <div className='coach-details'>
                        <div className='title'>{data?.coach_details?.name}</div>
                        <div className='info'>{data?.coach_details?.education}</div>
                      </div>
                    </div>
                  </CoachCard>:
                    <div
                      className="imageCard"
                      onClick={() =>
                        this.props.history.push(`/events/${data.id}`)
                      }
                    >
                      <img src={`${ImageUrl}/${data.image}`} alt="category" />
                    </div>}
                  <div className="bg-opacity"> </div>
                  <div className="detailsCard">
                    <div className="titleCard">
                      <div className="title">{this.props.t(data.title)}</div>
                      <div className="icon">
                        <span onClick={(event) =>this.toggleModal(data.id, event)} style={{cursor: "pointer"}} >{InfoIconV2()}</span>
                      </div>
                    </div>
                    <div className="time">
                      {convertMonthFromDate(moment(convertDateInTimezone(data && data.start_date)._d).format("MMM DD, hh:mm a"), this.props)}{" "}({data.abbreviation}) |{" "}{data.event_location ? this.props.t("Onsite") : this.props.t("Virtual")}
                    </div>
                    {data.is_lunch_and_learn_event === 1 && data.event_link ? null : <WolibaButton
                      onClick={(event) =>this.handleWolibaLive(data.id,data.is_attending, event)}
                      background={data.is_attending &&"white"}
                      margin={data && data.attendees_list && data.attendees_list.length < 3? "auto 27px auto auto": "auto 15px auto auto"}
                      style={{justifyContent:'center'}}
                      boxShadow={!data.is_attending && '0px 4px 12px rgba(105, 194, 255, 0.5)'}
                      color={data.is_attending && "#005c87"}
                      border={data.is_attending && "1px solid #005c87"}
                    >
                      <div className="title">
                        {data.is_attending ? (
                          <img
                            src="/public/images/Events/rightMark.png"
                            alt="arrow"
                          />) : null}
                        {!data.is_attending? this.props.t("Reserve Spot"): this.props.t("Spot Reserved")}
                      </div>
                      {/* <div className="participants">
                        {data && data.attendees_list && data.attendees_list.length > 0 && (
                          <div className="image">
                            <img
                              src={`${ImageUrl}/${data.attendees_list[0].profile_image}`}
                              alt="category"
                            />
                          </div>)}
                        {data && data.attendees_list && data.attendees_list.length > 1 && (
                          <div className="image">
                            <img
                              src={`${ImageUrl}/${data.attendees_list[1].profile_image}`}
                              alt="category"
                            />
                          </div>)}
                        {data && data.attendees_list && data.attendees_list.length > 2 && (
                          <div className="count">
                            {data.attendees_list.length - 2}
                          </div>)}
                      </div> */}
                    </WolibaButton>}
                  </div>
                  {eventCalendarId === data.id && showAboutEventModal && (
                    <AboutTheEventModal
                      showModal={showAboutEventModal}
                      onHide={this.toggleModalV2}
                      title={(data && data.title) || ""}
                      body={(data && data.body) || ""}
                    />
                  )}
                </WolibaCard>)): 
              <NoEvents>
                <LazyImage div={"imageCard"} src={`${ImageUrl}${"/images/Events/NoEvents.png"}`} alt="No-Events"/>
                {
                  role==="WELLNESS_ADMIN"||role==="ADMIN"?
                    <div className="text" style={{color:"#005c87",fontFamily:"rubik"}}>
                      {this.props.t("There is no Live Events in the here for your company?")}
                    </div>:
                    <div className="text" style={{color:"#005c87",fontFamily:"rubik"}}>
                      {this.props.t("There is no Live Event Created. Soon we will add a few events")} {this.props.t("till then See are OnDemand Classes")}
                    </div>}
                <div className="buttonContainer">
                  <div className="buttons">
                    <WolibaButton>
                      {role==="WELLNESS_ADMIN"||role==="ADMIN"?
                        <div className="button-title" onClick={() =>this.redirection("/company/events/create")}>
                          {this.props.t("Create Event")}
                        </div>:
                        <div className="button-title" onClick={() =>this.redirection("/events")}>
                          {this.props.t("Go to Events Dashboard")}
                        </div>
                      }
                    </WolibaButton>
                  </div>
                </div>
              </NoEvents>
            }
          </CompletedChallengeWrapper>
        </div>
      </LayoutNew>
    );
  }
}

LiveEventsMain.propTypes = {
  history: PropTypes.object.isRequired,
  getActiveEvents: PropTypes.func,
  activeEvents: PropTypes.array,
  totalCount: PropTypes.number.isRequired,
  attendEvent: PropTypes.func,
  role: PropTypes.string,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  activeEvents: state.events.activeEvents,
});

const mapDispatchToProps = (dispatch) => ({
  getActiveEvents: (pageNo) => dispatch(getActiveEvents(pageNo)),
});

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(LiveEventsMain));
